/* Change this file to get your personal Portfolio */

// Summary And Greeting Section

import emoji from "react-easy-emoji";

const illustration = {
  animated: true // set to false to use static SVG
};

const greeting = {
  username: "Mahmoud Salem",
  title: "Hi all, I'm Salem",
  subTitle: emoji(
    "A backend software engineer 🚀 who delivered high-quality software and automation services to customers from France, Germany, India and Egypt. I'm experienced in implementing high performance, scalable and well designed systems in Ruby, Go, Python, Java and JavaScript. Currently 📍 in London."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1RB3A9EK_TCTRPSL_Obc4C4lnB7Rx8Vt2/view?usp=sharing",
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/mahsayedsalem",
  linkedin: "https://www.linkedin.com/in/mahsayedsalem/",
  gmail: "mahsayedsalem@gmail.com",
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "I SCRATCH AND MAINTAIN GREAT SYSTEMS, CHANGING THE WORLD ONE SYSTEM AT A TIME",
  skills: [
    emoji(
      "⚡ I deliver fast programs, fast."
    ),
    emoji("⚡ I understand the client's business domain and needs."),
    emoji(
      "⚡ I'm a great communicator and clients tend to love working with me!"
    ),
    emoji(
      "⚡ I'm experienced in Digital Transformation."
    ),
    emoji(
      "⚡ I'm experienced in creating analytics and reporting services."
    ),
    emoji(
      "⚡ I'm experienced in integrating third party APIs/Payment Services."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Golang",
      image: require("./assets/images/go.png"),
      isImage: false,
      devicon: "devicon-go-plain"
    },
    {
      skillName: "Ruby",
      fontAwesomeClassname: "fas fa-gem",
      isImage: false
    },
    {
      skillName: "Python",
      fontAwesomeClassname: "fab fa-python",
      isImage: false
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js",
      isImage: false
    },
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java",
      isImage: false
    },
    {
      skillName: "TypeScript",
      fontAwesomeClassname: "fas fa-code",
      isImage: false,
      devicon: "devicon-typescript-plain"
    },
    {
      skillName: "Docker",
      fontAwesomeClassname: "fab fa-docker",
      isImage: false
    },
    {
      skillName: "Kubernetes",
      image: require("./assets/images/kubernetes.png"),
      isImage: true
    },
    {
      skillName: "NPM",
      fontAwesomeClassname: "fab fa-npm",
      isImage: false
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: "fab fa-aws",
      isImage: false
    },
    {
      skillName: "Postgres",
      fontAwesomeClassname: "fas fa-database",
      isImage: false,
      devicon: "devicon-postgresql-plain"
    },
    {
      skillName: "MongoDB",
      fontAwesomeClassname: "fas fa-file",
      isImage: false,
      devicon: "devicon-mongodb-plain"
    },
    {
      skillName: "Haskell",
      fontAwesomeClassname: "fas fa-file",
      isImage: false,
      devicon: "devicon-haskell-plain"
    },
    {
      skillName: "Scala",
      fontAwesomeClassname: "fas fa-file",
      isImage: false,
      devicon: "devicon-scala-plain"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Modern University of Science and Art",
      logo: require("./assets/images/msa_logo.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "2014 - 2018",
      desc: "In my Graduation Project I chose to work in medical imaging, where I utilized deep learning to detect Breast Cancer.",
      descBullets: [
        "Algorithms",
        "Database",
        "Distributed Systems",
        "Software Engineering",
        "Neural Networks",
      ]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend",
      progressPercentage: "80%"
    },
    {
      Stack: "Frontend",
      progressPercentage: "60%"
    },
    {
      Stack: "Data Engineering",
      progressPercentage: "60%"
    },
    {
      Stack: "Data Analysis",
      progressPercentage: "50%"
    },
    {
      Stack: "Machine and Deep Learning",
      progressPercentage: "50%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Backend Software Engineer",
      company: "Funding Circle",
      companylogo: require("./assets/images/fundingcircle.png"),
      date: "August 2021 – Present",
      desc:
        "Worked on a loan origination system in Ruby.",
      descBullets: [
        "Integrated 2nd look partners (e.g OnDeck, Fundera) to our system.",
        "Maintained 3rd Party services (e.g Experian, Salesforce) integration.",
        "Increased originations by 7% by changing borrowers' eligibility logic.",
        "On-boarded new engineers to both the business and services.",
        "Ruby| Rails| Kafka| AWS| RDS| Jenkins| Mesos/Marathon| CircleCI",
      ]
    },
    {
      role: "Backend Software Engineer",
      company: "Halan",
      companylogo: require("./assets/images/halan-3837.jpg"),
      date: "June 2020 – July 2021",
      desc:
        "Worked on a microfinance loan tracking system, which processes millions of transactions every day with high availability and low latency in Go.",
      descBullets: [
        "Built both the report and analysis microservices, which together analyze millions of transactions every day in Go| Python| Mongo| Postgres.",
        "Optimized the monthly reporting service reducing the response time by 80%",
        "Maintained and optimized Postgres queries that calculates crucial business statistics",
        "Go| Python| Mongo| Postgres| RabbitMQ| ElasticSearch| Cassandra Redis| Docker| Kubernetes| gRPC| gin| Minio",
      ]
    },
    {
      role: "Software Engineer",
      company: "Valeo",
      companylogo: require("./assets/images/Valeo-01.jpg"),
      date: "July 2018 – June 2020",
      desc:
        "Worked in a tooling service line, where I delivered services and software used by thousands of embedded software engineers around the world.",
        descBullets: [
          "Built a web application that retrieves and analyzes different data from 3 different systems to reflect the state of each project and its compliance with the standards. Used by senior management to make quick decisions on key projects or issues in Flask| Angular| Redis| Postgres| Docker.",
          "Built and maintained a microservice-based platform designed to automate tasks related to unit testing in all projects in Flask| Angular| Docker.",
          "Maintained a resource management system that allows team leaders to view their resources and assign workloads to them based on recommendations and statistics about the current workload of each resource in the team.",
          "Python| JavaScript| Bootstrap| TypeScript| Flask| Angular| Redis SQLAlchemy| Postgres| Docker| Jenkins| C#"
        ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
  githubUserName: "mahsayedsalem", // Change to your github username to view your profile in Contact Section.
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "",
  subtitle: "",
  projects: [],
  display: false // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements 🏆 "),

  achievementsCards: [
    {
      title: "HealthHackathon Winner 2018",
      subtitle:
        "Built a statistical model that automates the claims revisions task in health insurance companies. The prize was 30,000LE",
      image: require("./assets/images/health2.jpg"),
      footerLink: [
        {
          name: "Winners Declaration",
          url:
            "https://www.facebook.com/Health2.0Egypt/posts/2014411788819715"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle: "I love writing. I believe it's the best way to learn somethhing!",

  blogs: [
    {
      url:
        "https://blog.mahmoud-salem.net/egypts-tech-scene-salaries",
      title: "Egypt's Tech Scene",
      description:
        "Initiative to clear ambiguity of Egypt's tech market salaries, tech and work environment by collecting, analyzing and publishing data. It was read 100,000 times and some companies increased their salary ranges as a result. "
    },
    {
    url:
      "https://blog.mahmoud-salem.net/beyond-rest-grpc-in-microservices-part-i",
    title: "Beyond Rest: gRPC in Microservices Part I",
    description:
      "Building microservices in Go, Rust and Python while communicating via gRPC"
  },
  {
    url:
      "https://blog.mahmoud-salem.net/beyond-rest-grpc-in-microservices-part-ii",
    title: "Beyond Rest: gRPC in Microservices Part II - Streaming",
    description:
      "Use gRPC to stream content"
  }],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: "",

  talks: [],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: "",
  subtitle: "",

  // Please Provide with Your Podcast embeded Link
  podcast: [],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Want to ask something? My inbox is open for all.",
  number: "+44-7391-080259",
  email_address: "mahsayedsalem@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

export {
  illustration,
  greeting,
  socialMediaLinks,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails
};
